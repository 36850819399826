import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import AshcroftCodeForm from './AshcroftCodeForm.js';

class App extends Component {
  render() {
    return (
      <Container>
        <h1>Ashcroft-Hirlekar code converter</h1>
        <AshcroftCodeForm />
      </Container>
    );
  }
}

export default App;
