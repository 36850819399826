import mapping from './mapping.js';

export default function codeConverter(ashcroft) {
  // console.log(JSON.stringify(ashcroft, null, 2));
  let hirlekar = {
    type: "G",
    switch: "0",
    case: "S4",
    window: "F",
    seals: "B",
    options: ["C"]
  }

  let comments = [];

  ['dial', 'series', 'body', 'porting'].forEach(prop => hirlekar[prop] = mapping[prop][ashcroft[prop]]);
  if(hirlekar.series === '600DGC' && hirlekar.dial === '4.5')   hirlekar.dial = '4.4'
  comments.push(mapping.filling[ashcroft.filling]);
  if (ashcroft.filling === 'L') hirlekar.options.push('A');
  switch (ashcroft.connection) {
    case "25":
      hirlekar.connection = "4N";
      break;
    case "27":
      hirlekar.connection = "4B";
      break;
    case "RQ":
      hirlekar.connection = "8N";
      break;
    case "GE":
      hirlekar.connection = hirlekar.series === '200DPG' ? '8N' : 'ZZ';
      break;
    default:
      hirlekar.connection = "ZZ";
  }
  comments.push(mapping.connection[ashcroft.connection]);

  hirlekar.range = `(${ashcroft.centerZero ? (ashcroft.range+'-') : ''}0-${ashcroft.range}${mapping.units[ashcroft.units]}`;
  if (ashcroft.options.match(/CS/)) hirlekar.range += `/${ashcroft.centerZero ? (ashcroft.dualRange+'-') : ''}0-${ashcroft.dualRange}${mapping.units[ashcroft.dualUnits]})`
  else hirlekar.range += ')';

  let optionsDecoderPtr = 0;
  while (optionsDecoderPtr < ashcroft.options.length) {
    let [charsDecoded, comment] = optionsParser(hirlekar, ashcroft.options.slice(optionsDecoderPtr));
    if (comment) comments.push(comment);
    if (charsDecoded === 0) break;
    optionsDecoderPtr += charsDecoded;
  }

  if (hirlekar.switch !== '0' && ashcroft.porting === 'B' && hirlekar.series === '200DPG' && !hirlekar.options.includes('K')) {
    comments.push('32mm thick body');
    hirlekar.options.push('K');
  }


  let hirlekarCode, ashcroftCode;
  hirlekarCode = `${ashcroft.centerZero ? 'CZ' : ''}${hirlekar.series}-${hirlekar.type}-${hirlekar.body}-${hirlekar.dial}-${hirlekar.connection}-${hirlekar.porting}-${hirlekar.case}-${hirlekar.window}-${hirlekar.seals}-${hirlekar.switch}-${hirlekar.range}-${hirlekar.options.join('-')}`;

  ashcroftCode = `${ashcroft.dial}${ashcroft.series}${ashcroft.body}${ashcroft.filling}${ashcroft.connection}${ashcroft.porting}${ashcroft.options}${ashcroft.range}${ashcroft.units}${ashcroft.options.match(/CS/) ? `/${ashcroft.dualUnits}` : ''}`;

  return { hirlekarCode, ashcroftCode, comments };
}

function optionsParser(hirlekar, opt) {
  let chars = 0,
    msg = null;

  if (opt.startsWith('FF')) {
    hirlekar.case = 'F4';
    msg = 'Flanged case';
    if (opt[2] === "1") {
      hirlekar.dial = "6.0";
      chars = 3;
    } else chars = 2;
  } else if (opt.startsWith('V') && !isNaN(Number(opt[1]))) {
    hirlekar.type = "GS";
    hirlekar.switch = opt[1] + '[x]';
    chars = 2;
  } else if (opt.startsWith('VZ')) {
    hirlekar.type = "GS";
    hirlekar.switch = '82';
    chars = 2;
  } else if (opt.startsWith('VD')) {
    hirlekar.seals = 'V';
    msg = 'Viton seals/diaphragm';
    chars = 2;
  } else if (opt.startsWith('EM')) {
    hirlekar.seals = 'E';
    msg = 'EPDM seals/diaphragm';
    chars = 2;
  } else if (opt.startsWith('GR')) {
    let index = hirlekar.options.findIndex(element => element === 'A');
    if (index > 0) hirlekar.options[index] = 'A';
    else hirlekar.options.push('A');
    msg = 'Glycerine filling (affects accuracy)';
    chars = 2;
  } else if (opt.startsWith('GV')) {
    let index = hirlekar.options.findIndex(element => element === 'A');
    if (index > 0) hirlekar.options[index] = 'S';
    else hirlekar.options.push('S');
    msg = 'Silicone filling (affects accuracy)';
    chars = 2;
  } else if (opt.startsWith('EK')) {
    hirlekar.series = 'EX'.concat(hirlekar.series);
    msg = 'Explosion Proof';
    chars = 2;
  } else if (opt.startsWith('TM')) {
    hirlekar.options.push('M[x]');
    msg = '2" [Horizontal|Vertical] Pipe mounting bracket';
    chars = 2;
  } else if (opt.startsWith('BF')) {
    hirlekar.options.push('M2');
    msg = 'Surface mounting bracket';
    chars = 2;
  } else if (opt.startsWith('JL')) {
    msg = 'Adapter 3/4 NPT(M) x 1/2 NPT Br. Conduit(F)';
    chars = 2;
  } else if (opt.startsWith('NH')) {
    msg = 'SS Tags';
    chars = 2;
  } else if (opt.startsWith('NN')) {
    msg = 'Paper Tag (to be arranged by Ashcroft)';
    chars = 2;
  } else if (opt.startsWith('EP') || opt.startsWith('SH')) {
    hirlekar.options.push('B');
    hirlekar.window = 'A';
    msg = 'Follower pointer with Acrylic window';
    chars = 2;
  } else if (opt.startsWith('EO')) {
    msg = 'Red set pointer without bent leg';
    chars = 2;
  } else if (opt.startsWith('DH')) {
    chars = 2;
  } else if (opt.startsWith('PD')) {
    hirlekar.window = 'A';
    msg = 'Acrylic window';
    chars = 2;
  } else if (opt.startsWith('SG')) {
    hirlekar.window = 'L';
    msg = 'Safety glass window';
    chars = 2;
  } else if (opt.startsWith('TG')) {
    hirlekar.window = 'T';
    msg = 'Toughened glass window';
    chars = 2;
  } else if (opt.startsWith('CS')) {
    hirlekar.options.push('D');
    msg = 'Dual scale';
    chars = 2;
  } else if (opt.startsWith('ON')) {
    hirlekar.splice(hirlekar.options.indexOf("C"), 1);
    msg = 'Without Ashcroft logo';
    chars = 2;
  } else if (opt.startsWith('CYLM')) {
    chars = 4;
    msg = 'For Grainger';
  } else if (opt.startsWith('MN') || opt.startsWith('ZZ') || opt.startsWith('CK') || opt.startsWith('CY') || opt.startsWith('C9') || opt.startsWith('CG')) { // Internal Ashcroft coding
    chars = 2;
  } else if (opt.startsWith('EG') || opt.startsWith('FS')) {
    msg = 'Switch set point';
    chars = 2;
  } else if (opt.startsWith('E079')) {
    hirlekar.options.push('G');
    msg = 'Reverse porting';
    chars = 4;
  } else if (opt.startsWith('RA')) {
    msg = 'Red mark';
    chars = 2;
  } else if (opt.startsWith('RP')) {
    msg = 'Reverse porting';
    chars = 2;
  } else if (opt.startsWith('TS')) {
    msg = 'Teflon spring';
    chars = 2;
  } else if (opt.startsWith('YF') || opt.startsWith('VZ')) {
    msg = 'Silicone free product';
    chars = 2;
  } else if (opt.startsWith('VS')) {
    msg = 'Vacuum stop';
    chars = 2;
  } else if (opt.startsWith('YW')) {
    msg = 'SS316 case';
    chars = 2;
  } else if (opt.startsWith('DA')) {
    msg = 'Dial marking';
    chars = 2;
  } else if (opt.startsWith('DD')) {
    hirlekar.options.push('E');
    msg = 'Color band';
    chars = 2;
  } else if (opt.startsWith('CD') && !isNaN(Number(opt[2]))) {
    if (opt[2] === "2") msg = 'Test certificate EN10204';
    else if (opt[2] === "4") msg = 'Calibration certificate';
    else if (opt[2] === "5") {
      msg = 'NACE';
      hirlekar.options.push('N');
    } else if (opt[2] === "6") msg = 'Material test certificate';
    chars = 3;
  } else if (opt.startsWith('C') && !isNaN(Number(opt[1]))) {
    if (opt[1] === "1") msg = 'Certificate of conformance (T.C.) / Chamber of commerce';
    else if (opt[1] === "4") msg = 'Calibration certificate';
    else if (opt[1] === "7") console.log("PED Certificate / CE Certificate");
    chars = 2;
  } else if (opt.startsWith('HY')) {
    msg = 'Hydrostatic testing';
    chars = 2;
  } else if (opt.startsWith('6B') || opt.startsWith('6W')) {
    hirlekar.options.push('I');
    msg = 'Oxygen service';
    chars = 2;
  } else if (opt.startsWith('LJ')) {
    msg = 'Setup for liquid filling but not to add the glycerine';
    chars = 2;
  } else if (opt.startsWith('EW')) {
    msg = 'Adapter - Special 1/2 NPT conduit ad. for DIN plug to P sw';
    chars = 2;
  } else if (opt.startsWith('GM') || opt.startsWith('50') || opt.startsWith('2N')) {
    msg = 'Adapter - 1/2 NPT(F)';
    hirlekar.connection = 'ZZ';
    chars = 2;
  } else if (opt.startsWith('02')) {
    msg = 'Adapter - 1/4 NPT(M)';
    hirlekar.connection = 'ZZ';
    chars = 2;
  } else if (opt.startsWith('04')) {
    msg = 'Adapter - 1/2 NPT(M)';
    hirlekar.connection = 'ZZ';
    chars = 2;
  } else if (opt.startsWith('RQ')) {
    msg = 'Adapter - 1/8 NPT(F)';
    chars = 2;
  } else if (opt.startsWith('KH')) {
    msg = 'Special orientation / vertical calibration';
    chars = 2;
  } else if (opt.startsWith('KQ')) {
    msg = 'Adapter - 1/2 BSP in-built for DIN plug (1/2 DIN 2999)';
    chars = 2;
  } else if (opt.startsWith('K')) {
    if (hirlekar.series === '200DPG') {
      msg = '32 mm thick body';
      hirlekar.options.push('K');
    }
    chars = 1;
  } else if (opt.startsWith('GE')) {
    if(hirlekar.series === '200DPG') hirlekar.connection = '8N';
    else hirlekar.connection = 'ZZ';
    msg = 'Adapter - 1/8 NPT(F) connections';
    chars = 2;
  } else if (opt.startsWith('ADP')) {
    hirlekar.connection = 'ZZ';
    chars = 3;
  } else if (opt.startsWith('#4380')) {
    msg = 'Sigma Thermal Logo';
    chars = 5;
  }
  if (chars === 0) msg = 'Unrecognized options code: ' + opt;
  return [chars, msg];
}