import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import mapping from './mapping.js';
import codeConverter from './codeConverter.js';

export default class AshcroftCodeConverter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dial: "20",
      series: "1130",
      body: "F",
      filling: "D",
      connection: "25",
      porting: "S",
      options: "",
      range: '',
      units: '#',
      dualRange: '',
      dualUnits: '#',
      centerZero: false
    }
  }

  handleInputForm(field, e) {
    let state = this.state;
    state[field] = e.target.value;
    if(field === 'centerZero') state[field] = e.target.checked;
    if (field === 'series' && e.target.value === '1134') {
      state.body = 'E';
      state.filling = 'D';
      state.connection = 'RQ';
      state.porting = 'M';
    }
    this.setState(state);
  }

  handleOptionsText(e) {
    e.preventDefault();
    this.setState({ options: e.target.value.toUpperCase() });
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <Form inline>
              <Form.Control as="select" value={this.state.dial} onChange={this.handleInputForm.bind(this, 'dial')}>
                {Object.keys(mapping.dial).map((dialSize, i) => <option key={i} value={dialSize}>{dialSize}</option>)}
              </Form.Control>
              <Form.Control as="select" value={this.state.series} onChange={this.handleInputForm.bind(this, 'series')}>
                {Object.keys(mapping.series).map((seriesName, i) => <option key={i} value={seriesName}>{seriesName}</option>)}
              </Form.Control>
              <Form.Control as="select" value={this.state.body} onChange={this.handleInputForm.bind(this, 'body')}>
                {Object.keys(mapping.body).map((material, i) => <option key={i} value={material}>{material}</option>)}
              </Form.Control>
              <Form.Control as="select" value={this.state.filling} onChange={this.handleInputForm.bind(this, 'filling')}>
                {Object.keys(mapping.filling).map((filling, i) => <option key={i} value={filling}>{filling}</option>)}
              </Form.Control>
              <Form.Control as="select" value={this.state.connection} onChange={this.handleInputForm.bind(this, 'connection')}>
                {Object.keys(mapping.connection).map((size, i) => <option key={i} value={size}>{size}</option>)}
              </Form.Control>
              <Form.Control as="select" value={this.state.porting} onChange={this.handleInputForm.bind(this, 'porting')}>
                {Object.keys(mapping.porting).map((port, i) => <option key={i} value={port}>{port}</option>)}
              </Form.Control>
              -X&nbsp;
              <Form.Control placeholder="Options" value={this.state.options} onChange={this.handleOptionsText.bind(this)} />
              <Form.Control value={this.state.range} onChange={this.handleInputForm.bind(this, 'range')} placeholder="max DP"/>
              -
              <Form.Control as="select" value={this.state.units} onChange={this.handleInputForm.bind(this, 'units')}>
                {Object.keys(mapping.units).map((units, i) => <option key={i} value={units}>{units}</option>)}
              </Form.Control>
              <Form.Control disabled={!this.state.options.match(/CS/)} value={this.state.options.match(/CS/) ? this.state.dualRange : ''} onChange={this.handleInputForm.bind(this, 'dualRange')} placeholder="Dual scale max DP"/>
              <Form.Control as="select" disabled={!this.state.options.match(/CS/)} value={this.state.options.match(/CS/) ? this.state.dualUnits : '#'} onChange={this.handleInputForm.bind(this, 'dualUnits')}>
                {Object.keys(mapping.units).map((units, i) => <option key={i} value={units}>{units}</option>)}
              </Form.Control>
              <Form.Group>
                <Form.Label>CZ</Form.Label>
                <Form.Control type="checkbox" value={this.state.centerZero} onChange={this.handleInputForm.bind(this, 'centerZero')} />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <HirlekarCode {...codeConverter(this.state)} />
          </Col>
        </Row>
    </>
    )
  }
}

const HirlekarCode = ({ hirlekarCode, ashcroftCode, comments }) => (
  <Card className="hirlekar-code-card">
    <Card.Header><div>{ashcroftCode}<br/ >{hirlekarCode}</div></Card.Header>
    <Card.Body>{comments.map((comment, i) => <div key={i}>{comment}</div>)}</Card.Body>
  </Card>
);