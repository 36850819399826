export default {
  dial: {
    "20": "2.0",
    "25": "2.5",
    "35": "3.5",
    "40": "4.0",
    "45": "4.5",
    "60": "6.0"
  },
  series: {
    "1130": "200DPG",
    "1131": "200DGR",
    "1132": "300DGC",
    "1133": "400DGC",
    "1134": "600DGC"
  },
  body: {
    "E": "P",
    "F": "A",
    "A": "B",
    "S": "S"
  },
  filling: {
    "D": "Dry Filling",
    "L": "Liquid Filling (Affects accuracy) Default: Glycerine."
  },
  connection: {
    "02": "Adapter - 1/4 NPT(M)",
    "04": "Adapter - 1/2 NPT(M)",
    "13": "Adapter - 1/4 BSP(M)",
    "15": "Adapter - 1/2 BSP(F)",
    "25": "1/4 NPT(F)",
    "27": "1/4 BSP(F)",
    "14": "Adapter - 3/8 BSP(M)",
    "4T": "Adapter - 1/4 BSPT",
    "50": "Adapter - 1/2 NPT(F)",
    "RQ": "1/8 NPT(F)",
    "KQ": "Adapter - 1/2 BSP in-built for DIN plug (1/2 DIN 2999)",
    "GE": "Adapter - 1/8 NPT(F) connections",
    "EW": "Adapter - Special 1/2 NPT conduit adaptor for DIN plug to P sw"
  },
  porting: {
    "S": "1",
    "B": "2",
    "L": "3",
    "M": "1"
  },
  units: {
    "#": "psi",
    "MB": "mBar",
    "BR": "bar",
    "IW": "inH2O",
    "MW": "mmH2O",
    "PA": "Pa",
    "KP": "KPa",
    "MP": "MPa",
    "KSC": "kg/cm2"
  }
};
